import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  languages: [],
  language: null,
};

const languageSlice = createSlice({
  name: "Language",
  initialState,
  reducers: {
    fetchLanguages(state, action) {
      // state.Languages = [];
    },

    fetchLanguagesSuccess(state, action) {
      state.languages = action.payload;
    },

    fetchLanguageById(state, action) {
      state.language = null;
    },

    fetchLanguageByIdSuccess(state, action) {
      state.language = action.payload;
    },

    createLanguage(state, action) {},

    createLanguageSuccess(state, action) {
      console.log("www", action.payload);

      state.languages = [action.payload, ...state.languages];
    },

    updateLanguage(state, action) {
      state.language = null;
    },

    updateLanguageSuccess(state, action) {
      const index = state.languages.findIndex(
        i => i?.id === action?.payload?.id,
      );
      state.languages[index] = action.payload;
      state.languages = [...state.languages];
    },

    deleteLanguage(state, action) {},

    deleteLanguageSuccess(state, action) {
      state.languages = state?.languages.filter(
        item => item.id !== action.payload.id,
      );
    },
  },
});

// Actions
export const {
  fetchLanguageById,
  fetchLanguageByIdSuccess,
  fetchLanguages,
  fetchLanguagesSuccess,
  createLanguage,
  createLanguageSuccess,
  updateLanguage,
  updateLanguageSuccess,
  deleteLanguage,
  deleteLanguageSuccess,
} = languageSlice.actions;

// Reducer
const reducer = languageSlice.reducer;
export default reducer;
