// ** Reducers Imports
import navbar from "./navbar";
import layout from "./layout";
import auth from "./authentication";
import users from "@src/views/apps/user/store";
import kanban from "@src/views/apps/kanban/store";
import ecommerce from "@src/views/apps/ecommerce/store";
import dataTables from "@src/views/tables/data-tables/store";
import permissions from "@src/views/apps/roles-permissions/store";

// Hien

import bookListReducer from "./slices/adminUserSlice";
import userListReducer from "./slices/userSlide";
import matchDateListReducer from "./slices/matchesDateSlice";
import competitionListReducer from "./slices/competitionSlice";
import teamListReducer from "./slices/teamSlice";
import leaguesReducer from "./slices/leagueSlice";
import entriesData from "./slices/entriesDataSlice";
import generalReducer from "./slices/generalSlice";
import historyPlanReducer from "./slices/historyPlan";
import payments from "./slices/paymentSlice";
import entries from "./slices/entriesSlice";
import common from "./slices/commonSlice";
import entriesSetting from "./slices/entriesSettingSlice";
import comments from "./slices/commentsSlice";
import domains from "./slices/domainSlice";
import configDomains from "./slices/configDomainSlice";
import reports from "./slices/reportSlice";
import languages from "./slices/languageSlice";

const rootReducer = {
  auth,
  kanban,
  users,
  navbar,
  layout,
  ecommerce,
  dataTables,
  permissions,
  books: bookListReducer,
  user: userListReducer,
  matchesDate: matchDateListReducer,
  competitions: competitionListReducer,
  teams: teamListReducer,
  leagues: leaguesReducer,
  entriesData,
  entries,
  generals: generalReducer,
  historyPlan: historyPlanReducer,
  payments,
  common,
  entriesSetting,
  comments,
  domains,
  configDomains,
  reports,
  languages,
};

export default rootReducer;
