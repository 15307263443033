import {call, put, takeEvery, takeLatest} from "redux-saga/effects";
import toast from "react-hot-toast";

import {
  fetchLanguagesApi,
  fetchLanguageByIdApi,
  createLanguageApi,
  updateLanguageApi,
  deleteLanguageApi,
} from "../api";

import {
  fetchLanguages,
  fetchLanguagesSuccess,
  fetchLanguageById,
  fetchLanguageByIdSuccess,
  createLanguage,
  createLanguageSuccess,
  updateLanguage,
  updateLanguageSuccess,
  deleteLanguage,
  deleteLanguageSuccess,
} from "../redux/slices/languageSlice";

function* fetchLanguagesSaga(action) {
  try {
    const res = yield call(fetchLanguagesApi, {params: action.payload});

    yield put(fetchLanguagesSuccess(res?.data?.languages));
  } catch (error) {
    toast.error("Get errors !");
  }
}

function* fetchLanguageByIdSaga(action) {
  try {
    const res = yield call(fetchLanguageByIdApi, {params: action.payload});
    yield put(fetchLanguageByIdSuccess(res?.data?.user));
  } catch (error) {
    toast.error("Get errors !");
  }
}

function* createLanguageSaga(action) {
  try {
    const res = yield call(createLanguageApi, {params: action.payload});

    const {data, errors} = res;

    if (data?.createLanguage?.ok) {
      toast.success("Created success");
      yield put(createLanguageSuccess(data?.createLanguage?.data));
    } else {
      if (data.createLanguage?.error) {
        toast.error(`${data.createLanguage?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* deleteLanguageSaga(action) {
  try {
    const res = yield call(deleteLanguageApi, {params: action.payload});

    const {data, errors} = res;

    if (data.deleteLanguage?.ok) {
      toast.success("Deleted Success");
      yield put(deleteLanguageSuccess(data?.deleteLanguage?.data));
    } else {
      if (data.deleteLanguage?.error) {
        toast.error(`${data.deleteLanguage?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}
function* updateLanguageSaga(action) {
  try {
    const res = yield call(updateLanguageApi, {params: action.payload});

    const {data, errors} = res;

    if (data.updateLanguage?.ok) {
      toast.success("Updated Success");
      yield put(updateLanguageSuccess(data?.updateLanguage?.data));
    } else {
      if (data.updateLanguage?.error) {
        toast.error(`${data.updateLanguage?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

export default function* reportSaga() {
  yield takeEvery(fetchLanguages.type, fetchLanguagesSaga);
  yield takeEvery(fetchLanguageById.type, fetchLanguageByIdSaga);
  yield takeLatest(createLanguage.type, createLanguageSaga);
  yield takeLatest(deleteLanguage.type, deleteLanguageSaga);
  yield takeLatest(updateLanguage.type, updateLanguageSaga);
}
