import {gql} from "@apollo/client";

export const FETCH_GENERALS = gql`
  query generals($limit: Int) {
    generals(limit: $limit) {
      slider {
        link
        image
      }
      banner {
        link
        image
      }

      adsSliders {
        link
        image
      }
      timesPayment
      autoPayment
      plan1Month
      plan3Month
      plan6Month
      plan12Month
      tokensLimit
      adsCountTime
      freeChapters
      footerDescription
    }
  }
`;

export const UPDATE_GENERAL = gql`
  mutation updateGeneral(
    $id: ID!
    $slider: Object
    $banner: Object
    $adsSliders: Object
    $fileUpload: Upload
    $timesPayment: Int
    $autoPayment: Boolean
    $plan1Month: Int
    $plan3Month: Int
    $plan6Month: Int
    $plan12Month: Int
    $tokensLimit: Int
    $adsCountTime: Int
    $freeChapters: Int
    $footerDescription: String
  ) {
    updateGeneral(
      id: $id
      slider: $slider
      banner: $banner
      adsSliders: $adsSliders
      fileUpload: $fileUpload
      timesPayment: $timesPayment
      autoPayment: $autoPayment
      plan1Month: $plan1Month
      plan3Month: $plan3Month
      plan6Month: $plan6Month
      plan12Month: $plan12Month
      tokensLimit: $tokensLimit
      adsCountTime: $adsCountTime
      freeChapters: $freeChapters
      footerDescription: $footerDescription
    ) {
      data {
        slider {
          link
          image
        }
        banner {
          link
          image
        }
        adsSliders {
          link
          image
        }
        timesPayment
        autoPayment
        plan1Month
        plan3Month
        plan6Month
        plan12Month
        tokensLimit
        adsCountTime
        freeChapters
        footerDescription
      }
      ok
      error
    }
  }
`;
