import {apolloClient} from "../graphql/apolloClient";

import {
  FETCH_LANGUAGES,
  FETCH_LANGUAGE_BY_ID,
  UPDATE_LANGUAGE,
  CREATE_LANGUAGE,
  DELETE_LANGUAGE,
} from "../graphql/languages";

export function fetchLanguagesApi({token, params}) {
  return apolloClient.query({
    query: FETCH_LANGUAGES,
    variables: params,
  });
}
export function fetchLanguageByIdApi({params}) {
  return apolloClient.query({
    query: FETCH_LANGUAGE_BY_ID,
    variables: params,
  });
}

export function createLanguageApi({token, params}) {
  return apolloClient.mutate({
    mutation: CREATE_LANGUAGE,
    variables: params,
  });
}

export function updateLanguageApi({token, params}) {
  return apolloClient.mutate({
    mutation: UPDATE_LANGUAGE,
    variables: params,
  });
}

export function deleteLanguageApi({token, params}) {
  return apolloClient.mutate({
    mutation: DELETE_LANGUAGE,
    variables: params,
  });
}
