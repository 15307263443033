import {gql} from "@apollo/client";

export const FETCH_LANGUAGES = gql`
  query languages($limit: Int) {
    languages(limit: $limit) {
      id
      name
      link
      note
      status
    }
  }
`;

export const FETCH_LANGUAGE_BY_ID = gql`
  query language($id: ID!) {
    language(id: $id) {
      id
      name
      link
      note
      status
    }
  }
`;

export const CREATE_LANGUAGE = gql`
  mutation createLanguage($inputs: InputLanguage) {
    createLanguage(inputs: $inputs) {
      data {
        id
        name
        link
        note
        status
      }
      ok
      error
    }
  }
`;

export const UPDATE_LANGUAGE = gql`
  mutation updateLanguage($id: ID!, $inputs: InputLanguage) {
    updateLanguage(id: $id, inputs: $inputs) {
      data {
        id
        name
        link
        note
        status
      }
      ok
      error
    }
  }
`;
export const DELETE_LANGUAGE = gql`
  mutation deleteLanguage($id: ID!) {
    deleteLanguage(id: $id) {
      data {
        id
        name
        link
        note
        status
      }
      ok
      error
    }
  }
`;
